import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import SEO from "../components/layout/SEO"
import Container from "../components/layout/Container"
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import Divider from "../components/layout/Divider"
import Link from "../components/generic/Link"

// Styling
import textStyles from "../styles/textStyles"

interface IProps {
  data: {
    doc_title: string
    title: string
    description: {
      html: string
    }
    hero_image: {
      alt: string
      localFile: {
        childImageSharp: {
          fluid: any
        }
      }
    }
  }
}

const NotFoundPage = ({ data }: IProps) => (
  <>
    <SEO title={data.doc_title} />
    <StyledContainer>
      <Row>
        <Col mOffset={2} m={8}>
          <AnimatedTextScrollWrapper
            text={data.title.split(/\r?\n/)}
            textStyles={textStyles.headingM}
          />
        </Col>
        <Col mOffset={1} m={3}>
          <AnimatedScrollWrapper>
            <Description
              dangerouslySetInnerHTML={{ __html: data.description.html }}
            />
          </AnimatedScrollWrapper>
        </Col>
      </Row>
      <Row>
        <Col mOffset={2} m={14}>
          <StyledDivider />
        </Col>
      </Row>
      <Row>
        <Col mOffset={2} m={12}>
          <AnimatedScrollWrapper>
            <StyledImg
              fluid={data.hero_image.localFile.childImageSharp.fluid}
              alt={data.hero_image.alt}
            />
          </AnimatedScrollWrapper>
        </Col>
      </Row>
      <Row>
        <Col mOffset={2} m={12}>
          <AnimatedScrollWrapper>
            <Link type="internal" url="/" title="Go home" color="black" />
          </AnimatedScrollWrapper>
        </Col>
      </Row>
    </StyledContainer>
  </>
)

const StyledContainer = styled(Container)`
  margin-bottom: var(--base-column-size);
`

const Description = styled.div`
  display: flex;
`

const StyledDivider = styled(Divider)`
  margin-top: calc(var(--base-column-size) / 4);
`

const StyledImg = styled(Img)`
  width: 100%;
  margin-top: var(--base-column-size);
  margin-bottom: var(--base-column-size);
`

export const query = graphql`
  query notFoundPage {
    prismic404Page {
      data {
        doc_title
        title
        description {
          html
        }
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => (
  <NotFoundPage data={data.prismic404Page.data} />
)
